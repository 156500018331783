import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from 'vue-cookies'

// Bootstrap
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

// plugins/helpers
import plugins from "./plugins/helpers";
Vue.use(plugins);

// SVG inline
import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

Vue.config.productionTip = false;

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' , partitioned: false}
Vue.use(VueCookies, { expires: '6M'})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");

