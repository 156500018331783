import Vue from 'vue';
import Vuex from 'vuex';

/**
 * Import all vuex modules in a one-shot manner. There should not be any reason to edit this file.
 */
const files = require.context('.', false, /\.js$/)
const modules = {}
files.keys().forEach(key => {
    if (key === './index.js') return
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

Vue.use(Vuex);
export default new Vuex.Store({
    modules,
    strict: process.env.VUE_APP_ENVIRONMENT == 'local' // Disable strict mode for deployed environments to improve performance
});
