export default {
  namespaced: true,

  state: {
    viewWidth: window.innerWidth,
    visitedEuroFinans: false,
    visitedPrioritetFinans: false
  },

  mutations: {
    setViewWidth(state, newViewWidth) {
      state.viewWidth = newViewWidth;
    },
    setVisitedEuroFinans(state) {
      state.visitedEuroFinans = true;
    },
    setVisitedPrioritetFinans(state) {
      state.visitedPrioritetFinans = true;
    }
  }
};
